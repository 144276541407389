import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState  } from "react";
import Seo from "./Seo";

import { Hub } from 'aws-amplify/utils';
import { signInWithRedirect } from 'aws-amplify/auth';

import "bootstrap-icons/font/bootstrap-icons.css";
import useIsLoggedIn from "./useIsLoggedIn";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const provider = {
  custom: 'LinkedIn'
}


function Signin() {
  const [message, setMessage] = useState("");
  const [hasError, setHasError] = useState("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  let { target = "card" } = useParams();
  const [finalTarget, setFinalTarget] = useState(target);

  let handleSubmit = async (e) => {
    e.preventDefault();
  };
  
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn() === true) {
      navigate(`/${finalTarget}`);
    }
  }, [navigate, finalTarget, isLoggedIn]);

  const checkQueryStringForErrors = async () => {
    // hub.listen sometimes misses errors from SSO, handling query string too\
    const errorDescription = searchParams.get("error_description");
    const error = searchParams.get("error");

    if (error) {
      let strError = error;

      if (errorDescription) {
        strError += ": "  + errorDescription;
      }

      setMessage(strError);
      setHasError("alert-danger");
    }
  };

  useEffect(() => {
    checkQueryStringForErrors();

    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signIn':
          break;
        case 'signInWithRedirect':
          break;
        case 'tokenRefresh':
          break;
        case 'signInWithRedirect_failure':
          setMessage("An error has occurred during the OAuth flow: " + payload.data.error);
          setHasError("alert-danger");
          break;
        case 'customOAuthState':
          setFinalTarget(payload.data);
          break;

        default:
          break;
      }

    });

    return unsubscribe;
  });


  return (
    <div className="container">
      <Seo title="Sign in" />

      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <hr />
          <form className="form-signin needs-validation" action="/signin" method="post" onSubmit={handleSubmit} noValidate>
            <h2 className="form-signin-heading">Please, sign in</h2>
            <h5 className="form-signin-heading text-center">or <Link to="/signup">sign up</Link> for first time customers</h5>
            <br />
            {message && <div className={"alert " + hasError} role="alert">
              {message}
            </div>}
            {!isLoggedIn() && <div>
              <div className="d-grid gap-2">
                <br />
                <button className="btn btn-dark" onClick={() => signInWithRedirect({customState: target})}>
                  Sign in
                </button>
                <br />
                <button type="button" className="btn btn-primary" onClick={() => signInWithRedirect({ customState: target, provider: 'Google' })}>
                  <i className="bi bi-google" />
                </button>
              </div>
              <br />
              <div className="d-grid gap-2">
                <button className="btn btn-primary" onClick={() => signInWithRedirect({ customState: target, provider })}>
                  <i className="bi bi-linkedin" />
                </button>
              </div>
              </div>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signin;
