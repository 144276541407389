import Seo from "./Seo";
import { Link } from "react-router-dom";

function Playbooks() {
  return (
    <div className="container">
      <Seo title="Playbooks overview" />
      <h2>Game, playbooks and ground rules</h2>

      <div className="row featurette ">
        <div className="col-md-2 text-center">
          &nbsp;
        </div>

        <div className="col-md-8">
          <ul className="nav nav-tabs" id="draftTabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">Overview</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="player-tab" data-bs-toggle="tab" data-bs-target="#player-tab-pane" type="button" role="tab" aria-controls="player-tab-pane" aria-selected="true">Players</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="producer-tab" data-bs-toggle="tab" data-bs-target="#producer-tab-pane" type="button" role="tab" aria-controls="producer-tab-pane" aria-selected="false">Producers</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="sponsor-tab" data-bs-toggle="tab" data-bs-target="#sponsor-tab-pane" type="button" role="tab" aria-controls="sponsor-tab-pane" aria-selected="false">Sponsors</button>
            </li>
            {/* <li className="nav-item" role="presentation">
  <button className="nav-link" id="mission-control-tab" data-bs-toggle="tab" data-bs-target="#mission-control-tab-pane" type="button" role="tab" aria-controls="mission-control-tab-pane" aria-selected="false">Mission control</button>
</li>
<li className="nav-item" role="presentation">
  <button className="nav-link" id="stage-progress-tab" data-bs-toggle="tab" data-bs-target="#stage-progress-tab-pane" type="button" role="tab" aria-controls="stage-progress-tab-pane" aria-selected="false">Stage progress</button>
</li> */}
          </ul>
          <div className="tab-content" id="draftTabContent">
            <div className="tab-pane fade p-3 show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex="0">
              <h3>Overview</h3>
              <img className="featurette-image img-fluid" src="/img/main/playbooks.jpg" alt="1 2 3" width="800px" height="800px" />

              <p>Team formation is a challenging objective. Selecting a team is a critical decision, whether you want to <b>build</b> technology, <b>produce</b>, or <b>sponsor</b> a team.</p>

              <p>
                When a professional sports team is gathered each player knows their best position, where they play well. Powered by playbooks, this program is focused on setting
                up incentives for professional teams. Building, setting and growing them around responsibility of each role.
              </p>
              <p>Typical project starts with an idea, and then experiences the struggle of hiring: phone calls, interviews, multiple screening rounds.
                This results in time consuming process which carves a good part of productive time.</p>

              <p>Playbooks are transforming this experience. Instead of building a team around an idea, we form teams where ideas grow.
                Because as in professional sports strong players embracing their role and position are key incentive holding each technology team together.</p>

              <p>Company design is quite close to engineering. Except here a team is a building block. Playbooks allow to set and verify expectations to all parties upfront.
                This allows for the creation of reliable and predictable teams.</p>

              <p>These expectations are providing incentives for parties involved, culture, training, team structure, key measurements, objectives, development stack, operational and SDLC processes.
                Typically, teams are set up in a leadership as a service style for experienced players.</p>

              <h3>Elemental topologies</h3>
              <p>Let's take a step back. The entire ecosystem needs an improvement.
                At highest level switching focus from building team around idea to growing ideas from a team
                is equivalent of replacing <i>definite</i> game by <i>infinite</i> one.</p>

              <p>Let's look into typical team setups, their shortcomings, and glimpse on how <Link to="/practice">AnyIntelli</Link> offers to improve them.</p>

              <div className="row m-5">
                <div className="col-lg-6">
                  <img className="featurette-image img-fluid" src="/img/programs/element-1.png" alt="element 1" />
                </div>

                <div className="col-lg-6">
                  <h4>Heavy lifter</h4>
                  <p>
                    As qualified senior engineer, you might benefit from <Link to="/workshops">startup blueprint</Link>: this will be 
                    your operational armor, and framework when you can head more to your team,
                    and focus on storytelling.
                  </p>
                </div>
              </div>

              <div className="row m-5">
                <div className="col-lg-6">
                  <h4>Duo</h4>
                  <p>As a duo you can cover market research and building the product. Typically,
                     aiming to get as far as possible in customer value created.
                     Ideally, before you hit a milestone which requires to scale business.
                  </p>
                </div>
                <div className="col-lg-6">
                  <img className="featurette-image img-fluid" src="/img/programs/element-2.png" alt="element 2" />
                </div>
              </div>

              <div className="row m-5">
                <div className="col-lg-6">
                  <img className="featurette-image img-fluid" src="/img/programs/element-3.png" alt="element 3" />
                </div>

                <div className="col-lg-6">
                  <h4>Classical</h4>
                  <p>In this setup you have technical co-founder, business co-founder, and VCs which correspondingly build, produce, and sponsor. </p>
                  <p>As this trio is matched using <Link to="/practice">mission and vision</Link> (or similar) everyone wins:
                    investor is getting access to higher quality opportunities, and founders are getting long support horizon.</p>
                </div>
              </div>
              
              <p>These are elemental building blocks. As they compound, you are getting ecosystem for growth.
                Ecosystem where you can get <i>plasma teams</i>: "high temperature", and with positive energy output ;)</p>
            </div>

            {/* <div className="tab-pane fade  p-3" id="mission-control-tab-pane" role="tabpanel" aria-labelledby="mission-control-tab" tabIndex="0">
              <p>Stage: warm up 0.</p>
              <div className="progress mb-2" role="progressbar" aria-label="Example with label" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: "10%" }}>10.000001%</div>
              </div>
            </div> */}

            <div className="tab-pane fade p-3" id="player-tab-pane" role="tabpanel" aria-labelledby="player-tab" tabIndex="0">
              <h5>Who</h5>
              <ul>
                <li>experienced software engineers</li>
              </ul>

              <h5>Why</h5>
              <ul>
                <li>Interview where you are valued</li>
                <li>from selling oneself better in interviews</li>
                <li>to better self-reflection through scoring</li>
                <li>to better self-reflection through ranking</li>
                <li>to selling team, producing and sponsoring software innovations</li>
                <li>because you, your family, your team, and your company deserve better productivity</li>
              </ul>

              <h5>What</h5>
              <p><Link to="/elevate">Elevate</Link> your development via a <Link to="/workshops">startup blueprint</Link> and <Link to="/draft">draft</Link></p>

              <h5>When</h5>
              <p>As your demos mature, and closer you  get to blueprint</p>

              <h5>How</h5>
              <ul>
                <li><Link to="/signup">Sign up</Link>. Set goals. Demo. Rank. Refine. Repeat</li>
                <li>Play, rank, win, stay human afterwards. Repeat.</li>
                <li>Play, rank, lose, learn, and improve. Repeat.</li>
                <li>Score is important. Ranking is prioritizing.</li>
              </ul>
            </div>
            <div className="tab-pane fade p-3" id="producer-tab-pane" role="tabpanel" aria-labelledby="producer-tab" tabIndex="0">
              <h5>Who</h5>
              <ul>
                <li>experienced founders</li>
                <li>qualified players at step 3 of AnyIntelli Elevate</li>
                <li>select recruiters</li>
              </ul>
              <h5>What</h5>
              <ul>
                <li>Produce a team where ideas grow</li>
              </ul>
              <h5>When</h5>
              <p>When you discover a market theme (regardless of own technicality) for a product, or portfolio of products</p>

              <h5>Why</h5>
              <ul>
                <li>because engineering <Link to="/workshops">"right"</Link> shall meet business "now"</li>
                <li>ecosystem of growth</li>
                <li>long incentives</li>
              </ul>
              <h5>How</h5>
              <p>Get in touch with us sending brief <Link to="/intro/produce">intro</Link></p>
            </div>
            <div className="tab-pane fade p-3" id="sponsor-tab-pane" role="tabpanel" aria-labelledby="sponsor-tab" tabIndex="0">
              <h5>Who</h5>
              <ol>
                <li>experienced founders</li>
                <li>executives building startup culture within enterprise</li>
                <li>VC firms</li>
              </ol>
              <h5>What</h5>
              <p>
                Teams which deliver right, and now through
                team sustainably empowered by systematic producing.
              </p>

              <h5>When</h5>
              <p>See <Link to="/ata">ATA</Link> and <Link to="/workshops">startup blueprint</Link>.</p>
              <h5>Why</h5>
              <p>
                Sponsor meaningful relationships, for meaningful results.
              </p>
              <h5>How</h5>
              <p>Get in touch with us sending brief <Link to="/intro/sponsor">intro</Link></p>
            </div>

            <div className="tab-pane fade p-3" id="stage-progress-tab-pane" role="tabpanel" aria-labelledby="stage-progress-tab" tabIndex="0">
              <h5>Kickstart ourselves status</h5>
              <p>Spend 15 minutes filling your profiles. </p>
              <div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "1%" }}>1.000001%</div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  );
}

export default Playbooks;
