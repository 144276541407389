import { useQuery } from 'react-query';
import ApiClient from './ApiClient';

export function useIsLoggedIn () {
    const query = useQuery('isLoggedIn', ApiClient.isLoggedIn);

    return () => {return query && !query.isLoading && !query.error && query.data};
}

export default useIsLoggedIn;
