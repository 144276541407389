import Seo from "./Seo";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

function Elevate() {
  return (
    <div className="container">
      <Seo title="Elevate" />
      <h2>Elevate - 1, 2, 3<sup>beta</sup></h2>

      <div className="row featurette text-center mb-3">
        <div className="col-md-4 text-elevate-900 mb-1">

          <div className="card h-100 bc-elevate-premium1 shadow">
            <div className="card-body">
              <h4 className="card-title">Play</h4>
              <p className="card-text">Demo what you know.</p>
              <p className="card-text">Score, rank and stay human.</p>
              <p className="card-text">Interview with teams which value you.</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/signup/elevate-1">Sign up</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#player">Learn more</a>
            </div>
          </div>
        </div>

        <div className="col-md-4 text-dark mb-1">
          <div className="card h-100 bc-elevate-premium2 shadow">
            <div className="card-body">
              <h4 className="card-title">Train</h4>
              <p className="card-text">Get involved.</p>
              <p className="card-text">Understand your craft better.</p>
              <p className="card-text">Train interested groups.</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/signup/elevate-2">Sign up</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#trainer">Learn more</a>
            </div>

          </div>
        </div>

        <div className="col-md-4 mb-1">
          <div className="card h-100 bc-elevate-premium3 shadow">
            <div className="card-body">
              <h4 className="card-title">Spearhead. Coach</h4>
              <p>Leadership of service.</p>
              <p>Create environment of learning.</p>
              <p>Armor for your ideas.</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/signup/elevate-3">Sign up</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#coach">Learn more</a>
            </div>
          </div>
        </div>
      </div>

      <div className="row featurette">

        <div className="col-md-7">
          <img className="featurette-image img-fluid" src="/img/main/workshops2.jpg" alt="Growth" width="800px" height="800px" />
        </div>

        <div className="col-md-5">
          <h3><span className="first-letter">O</span>verview</h3>
          <p>AnyIntelli Elevate is focused on setting good incentives and meaningful productive career for seasoned professionals through sustainable development.</p>
          <p>Repetition and application of your strength starting from improved job search, to practicing your domain,
            and to spearheading innovative products elevating productivity via compounding cycle.</p>

          <p>Gradually prepare path from improving risk-averse jobs on meaningful mission, to training, and mastering a blueprint for spearheading.</p>
          <p>When there is no ladder, there is clear goal to increase productivity of end customer.</p>
          <p>These programs help iteratively understand where you are, and direct where you are going to.</p>

          <h3><span className="first-letter">R</span>emarks</h3>
          <p>A. Make sure to be aligned with growth environment <Link to="/practice">vision</Link>. No matter where in the process you are at.</p>
          <p>B. Play (1), Train (2), Spearhead (3.a), and Coach (3.b) are stepping stones. Capability of spearheading a new idea is key objective.
            Though, as your life priorities change you can transition from stage to stage too.</p>
          <p>C. Once you are <Link to="/signup">signed up</Link> for an account - please, indicate in your profile which programs you are interested in. System is adaptive.</p>
        </div>

        <div className="row">
          <div className="col-md-4 mb-1">
            <div className="card h-100 bc-secondary shadow">
              <div className="card-header text-center">
                <h3><span className="first-letter"  id="player">1</span> Player</h3>
              </div>

              <div className="card-body">
                <h5 className="card-title text-center" id="elevate1">Elevate your interview</h5>
                <p>
                  <i className="bi bi-info" /> The path can start as early as organized interviewing process with companies which are interested in your skills as you demo them.
                </p>
                <p>
                  <i className="bi bi-info" /> Rank among peers, get feedback, refine your skills. We all know the interview process is broken.
                  This is our offering to individuals and companies to fix it.
                </p>

                <p>
                  <i className="bi bi-info" /> We all know the interview process is broken. This is our offering to individuals and companies to fix it.
                </p>

              </div>
              <div className="card-footer text-center bg-transparent border-0">
                <Link className="btn btn-md btn-success" to="/signup/elevate-1">Sign up</Link>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-1">
            <div className="card h-100 bc-secondary shadow">
              <div className="card-header text-center">
                <h3><span className="first-letter" id="trainer">2</span> Trainer</h3>
              </div>
              <div className="card-body">
                <h5 className="card-title text-center" id="elevate2">Elevate others, learn yourself</h5>
                <p><i className="bi bi-info" />
                  Well tuned players can start training using their demos to refine it even further.
                </p>
                <p><i className="bi bi-info" />
                  One understands it better when they explain it.
                </p>
                <p><i className="bi bi-info" />
                  Get ready for spearheading.
                </p>
              </div>
              <div className="card-footer text-center bg-transparent border-0">
                <Link className="btn btn-md btn-success" to="/signup/elevate-2">Sign up</Link>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-1">
            <div className="card h-100 bc-secondary shadow">
              <div className="card-header text-center">
                <h3><span className="first-letter" id="coach">3</span> Spearhead. Coach</h3>
              </div>
              <div className="card-body">
                <h5 className="card-title text-center" id="elevate3">Elevate startup experience</h5>
                <p><i className="bi bi-info" /> As you go from a skills training to a complete blueprint in your area of interest you are getting into proper state when you can innovate spearheading startups.</p>
                <p><i className="bi bi-info" /> Discover opportunities to build, produce, or sponsor new ideas.</p>
                <p><i className="bi bi-info" /> Scalability is differentiating factor. Go beyond presentation.</p>
              </div>
              <div className="card-footer text-center bg-transparent border-0">
                <Link className="btn btn-md btn-success" to="/signup/elevate-3">Sign up</Link>
              </div>
            </div>
          </div>

        </div>


      </div>

    </div>
  );
}

export default Elevate;
