import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="m-0 p-0">
      <div className="container marketing">

        <div className="row featurette text-center">
          <div className="col-lg-12">
            <h2 className="featurette-heading fw-normal lh-1 text-center">Sustainable teams <span className="text-muted">driven by resiliency and reliability.</span></h2>

            <p className="lead">When engineering "right" meets business "now".</p>
          </div>
        </div>

        <div className="row featurette">
          <div className="col-lg-8">
            <img className="featurette-image img-fluid" src="/img/main/team2.jpg" alt="playbook" width="800px" height="800px" />{/*[1]*/}
          </div>

          <div className="col-lg-4">
            <h2 className="featurette-heading fw-normal lh-1">Meaningful game. <span className="text-muted">Impactful play.</span></h2>

            <p className="lead">Play. Train. Spearhead. Coach. </p>
            <p>
              <br />
              <Link className="btn btn-md btn-primary" to="/elevate">Elevate - 1, 2, 3</Link>
            </p>

          </div>

        </div>
      </div>

      <div className="container-fluid bg-light marketing">
        <div className="container ">
          <div className="row featurette ">

            <div className="col-lg-4">
              <h2 className="featurette-heading fw-normal lh-1">Build Teams.<span className="text-muted">Teams where ideas grow.</span></h2>
              <p className="lead">Set incentives, culture, stack. Set your Team.</p>
              <p>
                <br />
                <Link className="btn btn-md btn-dark" to="/draft">Team up</Link>
              </p>
            </div>

            <div className="col-lg-8 text-center">
              <img className="featurette-image img-fluid" src="/img/main/playbooks.jpg" alt="Scalable" width="700px" height="700px" />{/*[2]*/}
            </div>

          </div>
        </div>
      </div>

      <div className="container marketing">
        <div className="row featurette">

          <div className="col-lg-8">
            <img className="featurette-image img-fluid" src="/img/main/workshops2.jpg" alt="workshops" width="800px" height="800px" />{/*[3]*/}
          </div>

          <div className="col-lg-4">
            <h2 className="featurette-heading fw-normal lh-1">Join for Workshops. <span className="text-muted">Learn from practice.</span></h2>
            <p className="lead">Collection of workshops designed to guide software teams. Practical guiding stars.</p>
            <p>
              <br />
              <Link className="btn btn-md btn-dark" to="workshops">Build up a team</Link>
            </p>
          </div>

        </div>
        <div className="row featurette">
          <div className="col-lg-4">
            <h2 className="featurette-heading fw-normal lh-1">ATA. <span className="text-muted">Technology Acquisitions.</span></h2>
            <p className="lead">Make technology acquisition predictable. High to low level technology analysis, integration framework, and solutions.</p>
            <p>
              <br />
              <Link className="btn btn-md btn-dark" to="ata">Streamline acquisitions</Link>
            </p>
          </div>

          <div className="col-lg-8 text-center">
            <img className="featurette-image img-fluid" src="/img/main/ata.jpg" alt="Scalable" width="800px" height="800px" />{/*[2]*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
