import Seo from "./Seo";

function Signout() {  
  return (
    <div className="container">
      <Seo title="Sign out" />
      <div className="row text-center">
        <div className="col-sm-2">&nbsp;</div>

        <div className="col-sm-8">
          <h2>Successful signed out.</h2>
          <p>Thank you.</p>
        </div>
      </div>
    </div>
  );
}

export default Signout;
