import { Modal } from 'bootstrap';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import ApiClient from './ApiClient';
import { toast } from 'react-toastify';

export function DemoModal(props) {
  const [url, setURL] = useState("");
  const [description, setDescription] = useState("");
  const [removeFlag, setRemoveFlag] = useState(false);
  const [errors, setErrors] = useState({});
  const defaultInvalidFeedback = "This field needs a correction";


  useEffect(() => {
    let aURL = props.demo && props.demo.url ?  props.demo.url : "";
    setURL(aURL);

    let aDescription = props.demo && props.demo.description ?  props.demo.description : "";
    setDescription(aDescription);
  }, [props.demo, props.demo.description, props.demo.description]);

  const mutation = useMutation({
    mutationFn: (data) => {
      if (data.id) {
        return ApiClient.putDemoLikeThis({id: data.id, url: data.url, description: data.description});
      } else {
        return ApiClient.postDemoLikeThis(data);
      }
    },
    onError: (error, variables, context) => {
      toast.error(`Error: ${error}`);
    },
    onSuccess: async (data, variables, context) => {
      let res = await data.json();

      if (data.status === 200) {
        props.refetch();
        closeClick();
      } else {
        setErrors(res);

        // TODO: this should be standardized in other places
        if (res['overall'] !== undefined) {
          toast.error( "Error: " + res['overall'].join('.') );
        } else {
          toast.error("Error: " + data.statusText);
        }
      }
    }

  });

  const deletion = useMutation({
    mutationFn: (data) => {
      if (data.id) {
        return ApiClient.deleteDemoLikeThis({id: data.id});
      }
      return null;
    },
    onError: (error, variables, context) => {
      toast.error(`Error: ${error}`);
    },
    onSuccess: (data, variables, context) => {
      if (data.status === 200) {
        props.refetch();
        closeClick();
      } else {
        toast.error("Error: " + data.statusText);
      }
    }
  });


  const saveClick = function (e) {
    e.preventDefault();

    // Check validity locally first
    const form = e.target;
    if (!form.checkValidity()) {
      e.stopPropagation();
      form.classList.add('was-validated');
      return;
    } else {
      // If it is overall okay - send it to backend for further validation
      form.classList.remove('was-validated');
    }

    mutation.mutate({demo_kind_id: props.demoKind.id, url, id: props.demo.id, description});
  }

  const removeClick = function (e) {
    e.preventDefault();

    deletion.mutate({id: props.demo.id});
  }

  const closeClick = function () {
    setErrors({});
    setRemoveFlag(false);
    showDemoModal(false);
  }

  return (
    <div className="modal fade" id="demoModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="demoModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="demoModalLabel">{props.demoKind && props.demoKind.name}</h1>
            <button type="button" className="btn-close" onClick={closeClick} aria-label="Close"></button>
          </div>

          <form className="row g-3" noValidate onSubmit={saveClick} >
            <div className="modal-body">
              <p className="card-text">{props.demoKind && props.demoKind.description}</p>

              {props.demoKind && <p className="card-text"><span className="badge text-bg-success m-1">{props.demoKind.vertical}</span>
              &nbsp;<span className="badge text-bg-warning m-1">{props.demoKind.status}</span>
              &nbsp;<span className="badge text-bg-info m-1">{props.demoKind.sponsored}</span>
              </p>}

              <div className={"input-group " + (errors["URL"] && errors["URL"].length > 0 ? "is-invalid" : "is-valid")}>
                <label className="control-label col-12">URL</label>
                <input className={"form-control col-12 validate_url " + (errors["URL"] && errors["URL"].length > 0 ? "is-invalid" : "is-valid")} aria-describedby="validationURLFeedback" placeholder="Demo URL" required="required" type="url" value={url} onChange={(e) => setURL(e.target.value)} />
                <div id="validationURLFeedback" className="invalid-feedback col-12">{errors["URL"] && errors["URL"].length > 0 ? errors["URL"] : defaultInvalidFeedback}</div>
              </div>

              <div className={"input-group " + (errors["Description"] && errors["Description"].length > 0 ? "is-invalid" : "is-valid")}>
                <label className="control-label col-12">Description</label>
                <textarea rows="3" className={"form-control col-12 validation_required " + (errors["Description"] && errors["Description"].length > 0 ? "is-invalid" : "is-valid")} aria-describedby="validationDescriptionFeedback" placeholder="Demo description" required="required" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                <div id="validationDescriptionFeedback" className="invalid-feedback col-12">{errors["Description"] && errors["Description"].length > 0 ? errors["Description"] : defaultInvalidFeedback}</div>
              </div>
            </div>

            <div className="modal-footer">
                          {props.demo.id && <div className="modal-body">
              <p><input disabled={mutation.isLoading || deletion.isLoading} type="checkbox" id="scales" name="scales" checked={removeFlag} onChange={() => setRemoveFlag(!removeFlag)} /> <label>Remove this demo</label></p>
            </div>}
                <button type="button" className="btn btn-secondary" onClick={closeClick}>Close</button>

                {removeFlag === false && <button disabled={mutation.isLoading}  type="submit" className="btn btn-primary">{mutation.isLoading?"Saving changes...":"Save changes"}</button>}
                {removeFlag === true && <button disabled={deletion.isLoading} className="btn btn-danger" onClick={removeClick}>{deletion.isLoading?"Removing...":"Remove"}</button>}                
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

export function showDemoModal(flag) {
  if (flag) {
    Modal.getOrCreateInstance('#demoModal').show();
  } else {
    Modal.getOrCreateInstance('#demoModal').hide();
  }
}

export default DemoModal;
