import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        if (window.location.toString().search("#") === -1) {
            document.documentElement.scrollTo({behavior: "instant", top: 0, left: 0  });
        } else {
            const anchor = window.location.toString().split("#")[1];
            window.location.hash = "#" + anchor;
        }
    }, [pathname]);

    return null;
}

export default ScrollToTop;
