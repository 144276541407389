import Seo from "./Seo";
import { Link } from "react-router-dom";
import Locked from "./Locked";
import ApiClient from "./ApiClient";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DemoModal, showDemoModal } from "./DemoModal";
import { useEffect, useState } from "react";
import useIsLoggedIn from "./useIsLoggedIn";
import { toast } from 'react-toastify';

function ProfileHeader(props) {
  const { data } = props;
  const [notifyOptIn, setNotifyOptIn] = useState(false);
  const [newPhoto, setNewPhoto] = useState(null);
  const [newPhotoLocalUrl, setNewPhotoUrl] = useState("");
  const [profileVisibility, setProfileVisibility] = useState("");
  const isLoggedIn = useIsLoggedIn();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data && data.notify_opt_in !== undefined) {
      setNotifyOptIn(data.notify_opt_in);
      setProfileVisibility(data.profile_visibility);
    }

    if (data && data.goals !== undefined) {
      setGoals(data.goals);
    }

    if (data && data.goals_personal !== undefined) {
      setGoalsPersonal(data.goals_personal);
    }
  }, [data])

  const profileSave = useMutation({
    mutationFn: (data) => {
      if (data) {
        return ApiClient.putProfile(data);
      }
    },
    onSuccess: (data, variables, context) => {
      if (data.status === 200) {
        toast.info("Profile saved.");
        queryClient.invalidateQueries({ queryKey: ['getProfile'] })
      } else {
        toast.error("Error: " + data.statusText);
      }
    }
  });

  const profilePhotoSave = useMutation({
    mutationFn: (data) => {
      if (data) {
        return ApiClient.putProfilePhoto(data);
      }
    },
    onSuccess: async (data, variables, context) => {
      if (data.status === 200) {
        const res = await data.json();
        if (res.notices !== undefined) {
          toast.warning("Photo was saved. Wait a few minutes till change completes.");
        } else {
          toast.info("Photo saved.");
        }

        queryClient.invalidateQueries({ queryKey: ['getProfile'] })

        setNewPhoto(null);
      } else {
        toast.error("Error: " + data.statusText);
      }
    }
  });
 
  // const hasRankingsSet = function (data) {
  //   if (!data) return false;
  //   if (!data.rankings) return false;

  //   return true;
  // };

  const saveProfile = function () {
    if (isLoggedIn() && data) {
      profileSave.mutate({ "notify_opt_in": notifyOptIn, "profile_visibility": profileVisibility, goals: goals, "goals_personal": goalsPersonal });
      if (newPhoto) {
        profilePhotoSave.mutate(newPhoto);
      }
    }
  }

  const onProfileVisibilityOptionChange = e => {
    setProfileVisibility(e.target.value);
  }

  const imageOnClick = function () {
    const el = document.getElementById('profileupload');

    if (el.onclick) {
      el.onclick();
    } else if (el.click) {
      el.click();
    }
  }

  function uploadReady(event) {
    let str = event.target.result;
    str = str.replace('data:', '');
    let [baseType, data] = str.split(',', 2);
    let [type,] = baseType.split(';', 2);

    setNewPhoto({
      img_type: type,
      img_base64_data: data
    });
  }

  const [goals, setGoals] = useState({});

  const [goalsPersonal, setGoalsPersonal] = useState({});

  function handleGoalChange(aGoal) {
    let newGoal = {};
    newGoal[aGoal] = !goals[aGoal];
    setGoals({...goals, ...newGoal});
  }

  function handleGoalPersonalChange(aGoal) {
    let newGoal = {};
    newGoal[aGoal] = !goalsPersonal[aGoal];
    setGoalsPersonal({...goalsPersonal, ...newGoal});
  }

  const imagePreview = evt => {
    const profileUpload = document.getElementById('profileupload');
    const [file] = profileUpload.files;
    if (file) {
      setNewPhotoUrl(URL.createObjectURL(file));

      let reader = new FileReader();

      // Callback event
      reader.onload = uploadReady;
      reader.readAsDataURL(file);
    }
  }

  const getProfileImageUrl = () => {
    if (newPhotoLocalUrl !== "") {
      return newPhotoLocalUrl;
    } else {
      if (!data || !data.profile_photo_url || data.profile_photo_url === "") return "/img/main/incognito.jpg";

      return data?.profile_photo_url;
    }
  }

  return <div className="card bc-secondary">
    <div className="card-header text-center">
      <h3>{data && data.full_name}</h3>
      <i>{data && data.location}</i>
      <p>{data && data.email}</p>

      <img id="profileImage" className="rounded-circle shadow" onClick={imageOnClick} role="button" width="200px" height="200px" alt="Avatar" src={getProfileImageUrl()} />
      <input type="file" accept="image/png, image/jpeg" id="profileupload" style={{ display: "none" }} onChange={imagePreview} />

    </div>
    <div className="card-body">
      <h5 className="card-title" id="workshop3">Profile visibility</h5>
      <div className="text-center">
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio"
            name="inlineRadioOptions" id="inlineRadio1" value="public"
            checked={profileVisibility === "public"}
            disabled
            onChange={onProfileVisibilityOptionChange} />

          <label className="form-check-label" htmlFor="inlineRadio1">Public</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="sharable"
            checked={profileVisibility === "sharable"}
            onChange={onProfileVisibilityOptionChange} />

          <label className="form-check-label" htmlFor="inlineRadio2">Sharable</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="private"
            checked={profileVisibility === "private"}
            onChange={onProfileVisibilityOptionChange} />
          <label className="form-check-label" htmlFor="inlineRadio3">Only me</label>
        </div>
      </div>
    </div>

    <div className="card-body">
      <h5 className="card-title" id="workshop3">Goals</h5>
      <h6 className="text-center">{Object.keys(goals).map(goal => (<span key={"goal_" + goal}>
        <input type="checkbox" className="btn-check btn-xs" id={"btn-check-outlined" + goal} autoComplete="off" checked={goals[goal]} onChange={() => handleGoalChange(goal)} />
        <label className="btn btn-xs btn-outline-primary" htmlFor={"btn-check-outlined" + goal}>{goal}</label>&nbsp;
      </span>
      ))}
      {Object.keys(goalsPersonal).map(goal => (<span key={"goal_personal_" + goal}>
        <input type="checkbox" className="btn-check btn-xs" id={"btn-check-outlined" + goal} autoComplete="off" checked={goalsPersonal[goal]} onChange={() => handleGoalPersonalChange(goal)} />
        <label className="btn btn-outline-info btn-xs" htmlFor={"btn-check-outlined" + goal}>{goal}</label>&nbsp;
      </span>))}</h6>
    </div>

    <div className="card-body">
      <h5 className="card-title" id="workshop3">Notifications</h5>
      <p className="text-center"><input type="checkbox" id="notifications" name="notifications" checked={notifyOptIn} onChange={() => (setNotifyOptIn(!notifyOptIn))} /> <label>Email about relevant opportunities</label></p>
    </div>

    <div className="card-footer text-center bg-transparent border-0">
      <button disabled={profilePhotoSave.isLoading || profileSave.isLoading} className="btn btn-sm btn-success" onClick={() => (saveProfile(data))}>{profilePhotoSave.isLoading || profileSave.isLoading ? "Saving..." : "Save changes"}</button> &nbsp;

    </div>

    <div className="card-body">
      <h5 className="card-title" id="workshop3">Scores</h5>
      <p className="text-center"><i className="bi bi-clock-fill fs-7" /> Evaluation is in progress</p>
    </div>

    <div className="card-body">
      <h5 className="card-title" id="workshop3">Rankings</h5>
      <p className="text-center"><i className="bi bi-clock-fill fs-7" /> Evaluation is in progress</p>
    </div>

  </div>
}



function Profile() {
  const [activeDemo, setActiveDemo] = useState({});
  const isLoggedIn = useIsLoggedIn();

  const cardOnClick = function (demo) {
    if (isLoggedIn()) {
      setActiveDemo(demo);
      showDemoModal(true);
    }
  }
  const playersQuery = useQuery('getPlayerDemos', ApiClient.getPlayerDemos, { enabled: isLoggedIn() });
  const profileQuery = useQuery('getProfile', ApiClient.getProfile, { enabled: isLoggedIn() });
  const playbooksQuery = useQuery('getPlayerPlaybooks', ApiClient.getPlayerPlaybooks, { enabled: isLoggedIn() });

  const playbookDeletion = useMutation({
    mutationFn: (data) => {
      if (data.id) {
        return ApiClient.deletePlaybookLikeThis({ id: data.id });
      }
      return null;
    },
    onSuccess: (data, variables, context) => {
      if (data.status === 200) {
        playbooksQuery.refetch();
      } else {
        toast.error("Error: " + data.statusText);
      }
    }

  });

  return (
    <div className="container">
      <Seo title="Profile" />

      <div className="row">
        <Locked />
      </div>



      <div className="row p-3">
        <div className="col-lg-4">
          <ProfileHeader data={profileQuery.data} />
        </div>
        <div className="col-lg-8">
          <div className="row">
            <h3>Next step</h3>
            <p>Please, select relevant goals, add at least one demo, and pick at least one playbook to allow for scoring.</p>
          </div>
          <div className="row">
            <DemoModal demo={activeDemo} demoKind={playersQuery.data && playersQuery.data.demosMap && playersQuery.data.demosMap[activeDemo.demo_kind_id]} refetch={playersQuery.refetch} />
            <h3>My demos</h3>
            <div className="row featurette mb-1">
              <p className="text-center"><Link className="btn btn-sm btn-primary" to="/draft">Add more demos</Link></p>

              {playersQuery.isSuccess && playersQuery.data && playersQuery.data.playerDemos && playersQuery.data.playerDemos.map && playersQuery.data.playerDemos.map(demo => (
                <div key={demo.id} className="col-md-4 mb-3 text-">
                  <div className="card h-100 shadow">
                    <div className="card-body">
                      <h4 className="card-title">{playersQuery.data && playersQuery.data?.demosMap[demo.demo_kind_id] && playersQuery.data?.demosMap[demo.demo_kind_id].name}</h4>
                      <p className="card-text"></p>
                      <p className="card-text"><a href={demo.url} target="_blank" rel="noreferrer">{demo.description}<i className="bi bi-box-arrow-up-right fs-7" /></a></p>
                      <p className="card-text">{demo.endorsements && demo.endorsements.map(endorsement => (<span key={demo.id + "-" + endorsement} className="badge text-bg-success m-1">{endorsement}</span>))}</p>
                      <button className="btn btn-sm btn-light top-right" onClick={() => cardOnClick(demo)}><i className="bi bi-pen fs-7" /></button>
                    </div>
                  </div>
                </div>
              ))}
              {playersQuery.isLoading && <div className="card h-100">
                <div className="card-body">
                  <p className="card-text"> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> Loading</p>
                </div>
              </div>}
              {(playersQuery.isError || !playersQuery.data || !playersQuery.data.playerDemos || !playersQuery.data.playerDemos.length) && <div className="card h-100">
                <div className="card-body">
                  <p className="card-text"> No demos for now</p>
                </div>
              </div>}
            </div>
          </div>

          <div className="row">
            <h3>Playbooks followed</h3>
            <div className="row featurette mb-3">
              <p className="text-center"><Link className="btn btn-sm btn-primary" to="/draft">Add more playbooks/roles</Link></p>

              {playbooksQuery.isSuccess && playbooksQuery.data && playbooksQuery.data.playerPlaybooks && playbooksQuery.data.playerPlaybooks.map && playbooksQuery.data.playerPlaybooks.map(playbook => (
                playbook.id && <div key={playbook.id} className="col-md-4 mb-3 text-">
                  <div className="card h-100 shadow">
                    <div className="card-body">
                      <h4 className="card-title">{playbooksQuery.data && playbooksQuery.data?.playbooksMap[playbook.playbook_id] && playbooksQuery.data?.playbooksMap[playbook.playbook_id].name}</h4>
                      <p className="card-text">{playbooksQuery.data && playbooksQuery.data?.playbooksMap[playbook.playbook_id] && playbooksQuery.data?.playbooksMap[playbook.playbook_id].description_1}</p>
                      <p className="card-text">Objective: <span className="badge text-bg-info">{playbook.role}</span></p>
                    </div>
                    <div className="card-footer text-center bg-transparent border-0">
                      <button className="btn btn-sm btn-dark" onClick={() => playbookDeletion.mutate({ id: playbook.id })}>Unfollow</button>&nbsp;
                      <Link className="btn btn-sm btn-link" target="_blank" to={`/playbook/${playbook.playbook_id}`}>Learn more</Link>
                    </div>
                  </div>
                </div>
              ))}
              {playbooksQuery.isLoading && <div className="card h-100">
                <div className="card-body">
                  <p className="card-text"> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> Loading</p>
                </div>
              </div>}
              {(playbooksQuery.isError || !playbooksQuery.data || !playbooksQuery.data.playerPlaybooks || !playbooksQuery.data.playerPlaybooks.length) && <div className="card h-100">
                <div className="card-body">
                  <p className="card-text"> No playbooks for now</p>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
