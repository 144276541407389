import Seo from "./Seo";

function NotFound() {
  return (
    <div className="container">
      <Seo title="Page is not available" />
      <div className="row text-center">
          <div className="col-sm-2">&nbsp;</div>

          <div className="col-sm-8">
              <h1>404</h1>
              <h2>The page is not available.</h2>
              <p>Sorry, the requested page is not present at this server. It may be found using <a href="/">home page</a> or site menu above.</p>
          </div>
      </div></div>
  );
}

export default NotFound;
