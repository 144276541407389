import React from 'react';
import { Helmet } from 'react-helmet-async';

function Seo({ title, description, keywords }) {
    return (
        <Helmet>
            <title>{title ? title : 'Technology practice'} | AnyIntelli</title>
            <meta name='description' content={description ? description : 'AnyIntelli technology practice designs reliable cloud-based SaaS and builds teams.'} />
            <meta name="keywords" content={keywords ? keywords : 'IT research,data logistics,investment opportunities,advisory services'} />
            <meta name="description" content={description} />
        </Helmet>
    )
}

export default Seo;
