import Seo from "./Seo";
import Locked from "./Locked";

function Inbox() {
  return (
    <div className="container">
      <Seo title="Inbox" />
      <div className="row">
        <Locked />
        <div className="row featurette">
          <div className="col-md-12">
            <h2>Inbox</h2>
            <p>Matched opportunities will be displayed here.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inbox;
