import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage } from 'aws-amplify/utils';
import { fetchAuthSession } from 'aws-amplify/auth';
import { toast } from 'react-toastify';


class ApiClient {
  static config = {};
  static baseUrl = undefined;

  static async initBaseUrl(configUrl = '/params.json') {
    try {
      const res = await fetch(configUrl);
      const cfg = await res.json();

      Amplify.configure({ Auth: cfg.Auth });
      cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
      ApiClient.config = cfg;
      ApiClient.baseUrl = cfg.goa_base_url;
      return true;
    } catch (e) {
      toast.error("Configuration is not available, please, reload: " + e);
      return false;
    }
  };

  static async getTokens() {
    const nullAuth = { accessToken: null, idToken: null, isLoggedIn: false };

    if (ApiClient.config.Auth === undefined) return nullAuth;

    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      return {
        accessToken: accessToken.toString(),
        idToken,
        isLoggedIn: (accessToken !== null && accessToken !== undefined)
      };
    } catch (error) {
      return nullAuth;
    }
  }

  static async isLoggedIn() {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      return (accessToken !== null && accessToken !== undefined);
    } catch (error) {
      return false;
    }
  }

  static async getAuthorized(url) {
    const res = await ApiClient.send('GET', url, null, true);
    
    if (res.status >= 400) {
      throw new Error('Failed fetching data');
    }

    return await res.json();
  }

  static async send(method, url, data = null, authorized = true) {
    let sendObject = {
      method
    };

    if (authorized === true) {
      const { accessToken } = await ApiClient.getTokens();

      sendObject['headers'] = new Headers({
        'Authorization': 'Bearer ' + accessToken,
      });
    }

    if (data != null) {
      sendObject['body'] = JSON.stringify(data);
    }

    const res = await fetch(ApiClient.baseUrl + url, sendObject);
    return res;
  }

  static async getPublic(url) {
    const res = await ApiClient.send('get', url, null, false);

    return await res.json();
  }

  static async postPublic(url, data) {
    let res = await ApiClient.send('POST', url, data, false);

    return res;
  }

  static async getPlaybook(id) {
    const isAuth = await ApiClient.isLoggedIn();

    let res = null;
    if (!isAuth) {
      res = await ApiClient.send('get', `/draft/playbook/${id}`, null, false);
    } else {
      res = await ApiClient.send('get', `/user/draft/playbook/${id}`, null, true);
    }    

    return await res.json();
  }

  static async getProfile() {
    return ApiClient.getAuthorized(`/user/profile`);
  }

  static async putProfile(data) {
    return ApiClient.send('PUT', '/user/profile', data);
  }

  static async putProfilePhoto(data) {
    return ApiClient.send('PUT', '/user/profile/photo', data);
  }

  static async getProjects() {
    return ApiClient.send('GET', '/project/');
  }

  static async getPlaybooks() {
    const isAuth = await ApiClient.isLoggedIn();

    let res = null;
    if (!isAuth) {
      res = await ApiClient.send('get', '/draft/playbooks', null, false);
    } else {
      res = await ApiClient.send('get', '/user/draft/playbooks', null, true);
    }    

    if (res.status >= 400) {
      throw new Error('Failed fetching playbooks descriptions');
    }

    return await res.json();
  }

  static async getPublicDemos() {
    return ApiClient.getPublic('/draft/demos');
  }

  static async postDemoLikeThis(data) {
    return ApiClient.send('POST', '/demos/player', data);
  }

  static async putDemoLikeThis(data) {
    return ApiClient.send('PUT', `/demos/player/${data.id}`, data);
  }

  static async deleteDemoLikeThis(data) {
    return ApiClient.send('DELETE', `/demos/player/${data.id}`);
  }

  static async getPlayerDemos() {
    let playerDemosRes = ApiClient.send('GET', `/demos/player`);
    let demosDataRes = ApiClient.send('GET', '/draft/demos', null, false);

    if ((await playerDemosRes).status >= 400) {
      throw new Error('Failed fetching your demos');
    }

    if ((await demosDataRes).status >= 400) {
      throw new Error('Failed fetching demo descriptions');
    }

    let playerDemos = await (await playerDemosRes).json();
    let demosData = await (await demosDataRes).json();

    let demosMap = {};
    demosData.map(
      demo => (demosMap[demo.id] = demo)
    );

    return { playerDemos, demosMap };
  }

  
  static async getPlayerPlaybooks() {
    let playerPlaybooksRes = ApiClient.send('GET', `/playbooks/player`);
    let playbooksDataRes = ApiClient.send('GET', '/draft/playbooks', null, false);

    if ((await playerPlaybooksRes).status >= 400) {
      
      let errorDetails = "";
      let playerPlaybooksError = await (await playerPlaybooksRes).json();
      if (playerPlaybooksError.error) {
        errorDetails = ': ' + playerPlaybooksError.error;
      }

      throw new Error('Failed fetching your playbooks' + errorDetails);
    }

    if ((await playbooksDataRes).status >= 400) {
      throw new Error('Failed fetching playbooks descriptions');
    }

    let playerPlaybooks = await (await playerPlaybooksRes).json();
    let playbooksData = await (await playbooksDataRes).json();

    let playbooksMap = {};
    playbooksData.map(
      playbook => (playbooksMap[playbook.id] = playbook)
    );

    return { playerPlaybooks, playbooksMap };
  }

  static async postPlaybookLikeThis(data) {
    return ApiClient.send('POST', '/playbooks/player', data);
  }

  static async putPlaybookLikeThis(data) {
    return ApiClient.send('PUT', `/playbooks/player/${data.id}`, data);
  }

  static async deletePlaybookLikeThis(data) {
    return ApiClient.send('DELETE', `/playbooks/player/${data.id}`);
  }
}

export default ApiClient;

// 1. https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
