import { Link } from "react-router-dom";

function Footer() {
    var currentYear = new Date().getFullYear();
    
    //https://github.com/johnridesabike/coronate/blob/master/src/HtmlEntities.res
    const separator = '\u00A0\u00B7\u00A0';

    return (
        <div className="container-fluid text-center p-3">
            <hr className="featurette-divider" />
            <footer>
                <p>
                    {separator}
                    <Link to="/archive">Archive</Link>
                    {separator}
                    <Link to="/contact">Contacts</Link>
                    {separator}
                    <a href="https://www.linkedin.com/company/anyintelli" target="_blank" rel="noreferrer">LinkedIn</a>
                    {separator}
                </p>
                <p>
                    <Link to="/privacy">Privacy policy</Link>
                    {separator}
                    <Link to="/terms">Terms and conditions</Link>
                </p>

                <p>&copy;   {currentYear} AnyIntelli, Inc.           </p>
            </footer>
        </div>

    );
}

export default Footer;
