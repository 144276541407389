import Seo from "./Seo";
import { useNavigate } from "react-router-dom";
import ApiClient from "./ApiClient";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { DemoModal, showDemoModal } from "./DemoModal";
import { useState } from "react";
import useIsLoggedIn from "./useIsLoggedIn";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

function Draft() {
  const [activeDemo, setActiveDemo] = useState({});
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  const cardOnClick = function (demo) {
    if (isLoggedIn()) {
      setActiveDemo(demo);
      showDemoModal(true);
    } else {
      navigate('/signin');
    }
  }

  const playbookOnClick = function (playbook, role) {
    if (isLoggedIn()) {
      playbookMutation.mutate({ playbook_id: playbook.id, role: role });
    } else {
      navigate('/signin');
    }
  }

  const playbookMutation = useMutation({
    mutationFn: (data) => {
      return ApiClient.postPlaybookLikeThis(data);
    },
    onError: (error, variables, context) => {
      toast.error(`Error: ${error}`);
    },
    onSuccess: async (data, variables, context) => {
      if (data.status === 200) {
        queryClient.invalidateQueries({ queryKey: ['getPlayerPlaybooks'] });
        navigate('/card');
      } else {

        let res = await data.json();
        if (res && res.error) {
          toast.warning("Error: " + res.error);
        } else {
          toast.error("Error: " + data.statusText);
        }
      }
    }
  });

  const hasGoalsSet = function (data) {
    if (!data) return false;
    if (!data.goals) return false;

    // Iterate over goals
    const kGoals = Object.keys(data.goals);
    for (let i = 0; i < kGoals.length; i++) {
      if (data.goals[kGoals[i]] === true) {
        return true;
      }
    }

    if (!data.goals_personal) return false;
    // Iterate over goals
    const kGoalsPersonal = Object.keys(data.goals_personal);
    for (let i = 0; i < kGoalsPersonal.length; i++) {
      if (data.goals_personal[kGoalsPersonal[i]] === true) {
        return true;
      }
    }

    return false;
  };

  const queryClient = useQueryClient();
  const refetchOnSuccess = function () {
    queryClient.invalidateQueries({ queryKey: ['getPlayerDemos'] })
    navigate('/card');
  }

  const playbookQuery = useQuery('playbooks', ApiClient.getPlaybooks);
  const demosQuery = useQuery('demos', ApiClient.getPublicDemos);
  const profileQuery = useQuery('getProfile', ApiClient.getProfile, { enabled: isLoggedIn() });

  return (
    <div className="container">
      <DemoModal demoKind={activeDemo} demo={{}} refetch={refetchOnSuccess} />
      <Seo title="Draft" />

      <div className="row">
        <div className="col-md-6 mb-1">
          <h2>Demos</h2>
          <div className="row text-center mb-1">
            <div key="demos" className="col-md-12 mb-1">
              <p><Link to="/elevate">Elevate</Link> your ability to deliver results. What's the best question to show yourself from the best side? Tell your O.M.L.E.T. story using demos. <a href='/playbooks' target="_blank">Learn more</a>.</p>
            </div>
          </div>

          <div className="row mb-3">
            {demosQuery && demosQuery.data && demosQuery.data.map(demo => !demo.is_meta_demo && (
              <div key={demo.id} className="col-md-6 mb-3">
                <div className="card h-100 shadow">
                  <div className="card-header">
                    <h4 className="card-title">{demo.name}</h4>
                  </div>
                  <div className="card-body">
                    <p className="card-text">{demo.description}</p>
                    <p className="card-text">{demo.description_2}</p>
                    <p className="card-text"><span className="badge text-bg-success m-1">{demo.vertical}</span>
                      &nbsp;<span className="badge text-bg-warning m-1">{demo.status}</span>
                      &nbsp;<span className="badge text-bg-info m-1">{demo.sponsored}</span>
                    </p>
                  </div>

                  <div className="card-footer text-center bg-transparent border-0">

                    <button className="btn btn-sm btn-dark" onClick={() => cardOnClick(demo)}>Demo like this</button>
                  </div>
                </div>
              </div>
            ))}
            {(!demosQuery || demosQuery.isLoading || !demosQuery.data || demosQuery.data.length === 0) && <div className="card h-100" role="button">
              <div className="card-body">
                <p className="card-text"> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> No demos for now</p>
              </div>
            </div>}

          </div>
        </div>

        <div className="col-md-6 mb-1">

          <h2 id="playbooks">Playbooks</h2>
          <div className="row text-center mb-1">
            <div className="col-md-12 mb-1">
              <p>This is iterative progress. Playbooks give clear perspective (at higher levels) to incentives, culture, projects, and player roles. Start with a playbook archetype below. <a href='/playbooks' target="_blank">Learn more</a>.</p>
            </div>
          </div>


          <div className="row mb-3">
            {playbookQuery && playbookQuery.data && playbookQuery.isSuccess && playbookQuery.data.map(playbook => (
              <div key={playbook.id} className="col-md-12 mb-3">

                {!playbook.is_locked && !playbook.is_evaluating
                  && <div className="card h-100 shadow">
                    <div className="card-header">
                      <h4 className="card-title">{playbook.name} </h4>
                    </div>
                    <div className="card-body">
                      <span className="badge rounded-pill text-bg-secondary">{playbook.memberLevel}</span>
                      <p className="card-text">Size: <span className="badge text-bg-secondary">{playbook.min_size === playbook.max_size ? playbook.min_size : `${playbook.min_size}-${playbook.max_size}`}</span></p>
                      <p className="card-text">{playbook.description_1}</p>
                      <p className="card-text">{playbook.description_2}</p>
                    </div>
                    <div className="card-footer text-center bg-transparent border-0">
                      <button className="btn btn-sm btn-primary" onClick={() => playbookOnClick(playbook, "produce")}>Produce</button>&nbsp;
                      <button className="btn btn-sm btn-dark" onClick={() => playbookOnClick(playbook, "join")}>Follow</button>&nbsp;
                      <Link className="btn btn-sm btn-link" target="_blank" to={`/playbook/${playbook.id}`}>Learn more</Link>
                    </div>
                  </div>}

                {playbook.is_locked && <div className="card h-100 shadow" role="button">
                  <div className="card-header">
                    <h4 className="card-title"><i className="bi bi-lock-fill fs-4" /> Further content requires active subscription</h4>
                  </div>
                  <div className="card-footer text-center bg-transparent border-0">
                    <Link to="/signup" className="btn btn-sm btn-primary">Sign up</Link>&nbsp;
                    <Link className="btn btn-sm btn-dark" to="/signin/draft">Sign in</Link>
                  </div>
                </div>}

                {playbook.is_evaluating && <div className="card h-100 shadow" role="button">
                  <div className="card-header">
                    <h4 className="card-title"><i className="bi bi-clock-fill fs-4" />  Evaluation is in progress</h4>
                  </div>
                  <div className="card-footer text-center bg-transparent border-0">
                    <p className="card-text">More relevant playbooks will be available as evaluation of your profile progresses.</p>
                    {profileQuery.isSuccess && !hasGoalsSet(profileQuery.data) && <h5><span className="badge text-bg-warning m-1">Fill goals section in your <Link to="/card">profile</Link>.</span></h5>}


                  </div>
                </div>}
              </div>
            ))}

            {(!playbookQuery || !playbookQuery.data || playbookQuery.data.length === 0) && <div className="card h-100" role="button">
              <div className="card-body">
                <p className="card-text"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> No playbooks for now </p>
              </div>
            </div>}

          </div>
        </div>
      </div>
    </div>
  );
}

export default Draft;
