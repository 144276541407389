import React from 'react';
import ReactDOM from 'react-dom/client';

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ApiClient from './components/ApiClient';


await ApiClient.initBaseUrl();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // Comented out StrictMode due to double mount issue: https://www.techiediaries.com/react-18-useeffect/
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
