import Seo from "./Seo";
import { MailingAddress } from "./Contact";

function Terms() {
    return (
        <div className="container">
            <Seo title="Terms of Use" />
            <div className="row">
                <h2 >Terms and conditions</h2>
                <p>
                    <strong>Last Updated:</strong> 6/25/2024</p>
                <p><strong>Welcome to AnyIntelli, Inc.</strong></p>
                <p>These Terms of Use ("Terms") govern your access to and use of the associated website (anyintelli.com) (the "Site"), and any related services offered by AnyIntelli, Inc. ("we," "us," or "our").</p>
                <p><strong>1. Acceptance of Terms</strong></p>
                <p>By accessing or using the Service, you agree to be bound by these Terms.  If you do not agree to all of the Terms, you are not authorized to use the Service.</p>
                <p><strong>2. Eligibility</strong></p>
                <p>You must be at least 18 years old and have the legal authority to enter into agreements in the United States to use the Service.</p>
                <p><strong>3. Account Creation</strong></p>
                <p>You may need to create an account to access certain features of the Service. <span>You are responsible for maintaining the confidentiality of your account information, including your password. You agree to</span> be responsible for all activities that occur under your account.</p>
                <p><strong>4. Grant of License</strong></p>
                <p>We grant you a non-exclusive, non-transferable, limited license to use the Service for your internal business purposes in the United States only. You may not:</p>
                <ul><ul>
                    <li >Sublicense, assign, or otherwise transfer your rights under these Terms.</li>
                    <li >Modify, reverse engineer, decompile, disassemble, or create derivative works of the Service.</li>
                    <li >Use the Service for any illegal or unauthorized purpose.</li>
                    <li >Interfere with or disrupt the Service or any servers or networks connected to the Service.</li>
                </ul></ul><p><strong>5. User Content</strong></p>
                <p>You are responsible for all content ("User Content") you upload, post, or otherwise transmit through the Service. You retain all ownership rights to your User Content, but you grant us a non-exclusive, royalty-free license to use, reproduce, modify, publish, and distribute your User Content in connection with the Service.</p>
                <p><strong>6. Intellectual Property</strong></p>
                <p>The Service and all intellectual property rights associated therewith, including copyrights, trademarks, and patents, are owned by us or our licensors. You agree not to remove or alter any proprietary notices on the Service.</p>
                <p><strong>7. Third-Party Services</strong></p>
                <p>The Service may contain links to or integrate with third-party services.  Your use of such services is governed by the terms of service of those providers.  We are not responsible for the content or practices of any third-party services.</p>
                <p><strong>8. Disclaimers</strong></p>
                <p>THE SERVICE IS PROVIDED "AS IS" AND WITHOUT WARRANTY OF ANY KIND, EXPRESS <span >OR IMPLIED.  WE DISCLAIM ALL WARRANTIES, INCLUDING, </span><span>BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR</span><span> PURPOSE, AND NON-INFRINGEMENT.  WE DO NOT</span> WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.  YOU USE THE SERVICE AT YOUR OWN RISK.</p>
                <p><strong>9. <span >Limitation of Liability</span></strong></p><span >
                </span><p><span >TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE </span><span>SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,</span><span> CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF</span> OR RELATING TO YOUR USE OF THE SERVICE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                <p><strong>10. Term and Termination</strong></p>
                <p>These Terms shall remain in full force and effect until terminated by you or us.  We may terminate these Terms with or without cause at any time.  Upon termination, you will cease all use of the Service.</p>
                <p><strong>11. <span >Governing Law</span></strong></p><span >
                </span><p><span >These Terms shall be </span><span>governed by and construed in accordance with the laws of the State of New York, without regard to its</span><span> conflict of laws provisions.</span></p>
                <p><strong>12. <span >Dispute Resolution</span></strong></p><span >
                </span><p><span >Any dispute arising out of or relating to these Terms </span><span>shall be resolved by binding arbitration in accordance</span><span> with the rules of the American Arbitration Association.  The arbitration shall</span> be held in New York, New York.</p>
                <p><strong>13. Entire Agreement</strong></p>
                <p>These Terms constitute the entire agreement between you and us regarding the use of the Service.</p>
                <p><strong>14. Severability</strong></p>
                <p><span>If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall</span> remain in full force and effect.</p>
                <p><strong>15. Waiver</strong></p>
                <p>Our failure to enforce any provision of these Terms shall not constitute a waiver of such provision.</p>
                <p><strong>16. Updates to Terms</strong></p>
                <p>We <span>may update these Terms from time to time.  We will notify you of any changes by posting the new Terms on the Site.  You are advised to review these Terms periodically for any changes.</span>  Your continued use of the Service after the posting of revised Terms means that you accept and agree to the changes.</p>
                <p><strong>17. Contact Us</strong></p>
                <p>If you have any questions about these Terms, please contact us at compliance@anyintelli.com or by mail at:</p>
                <MailingAddress />

            </div>
        </div>
    );
}

export default Terms;
