import Seo from "./Seo";
import { Link } from "react-router-dom";
export function MailingAddress() {
  return (<div className="alert">
    AnyIntelli, Inc.<br />
    80 Broad Street, Floor 5<br />
    New York, NY 10004
  </div>)
}
function Contact() {

  return (
    <div className="container">
      <Seo title="Contact us" description='AnyIntelli contacts: address, email, and phone' keywords='AnyIntelli contact'  />
      <h2>Contact information</h2>

      <div className="row text-center">
        <div className="col-md-4">
          <h3>Mailing address</h3>
          <MailingAddress />
        </div>

        <div className="col-md-4">
        <h3>Intro & Contact</h3>
          <div className="alert">
            Follow O.M.L.E.T. process to <Link className="btn btn-sm btn-success" to="/intro">Request an intro</Link> personalized for your business.
          </div>
        </div>

        <div className="col-md-4">
          <h3>Email address</h3>
          <div className="alert">
            <a href="mailto:hello@anyintelli.com">hello@anyintelli.com</a>
          </div>

          <h3>Phone in NYC</h3>
          <div className="alert">
            +1 (917) 740-3691
          </div>

          {/* <h3>Social networks</h3>
              <div className="alert">
              <a target="_blank" href="https://www.facebook.com/AnyIntelli">Facebook Page</a> | <a target="_blank" href="https://twitter.com/AnyIntelli">Twitter Page</a>
              </div> */}
        </div>
      </div>

      <h2>Map</h2>
      <iframe title="our location" width="100%" height="350" src="https://maps.google.com/maps?f=d&amp;source=s_d&amp;saddr=80+Broad+Street,+New+York,+NY+10004&amp;sspn=0.007493,0.02&amp;spn=0.006295,0.00831&amp;t=m&amp;output=embed"></iframe><br /><small><a href="https://www.google.com/maps/place/80+Broad+Street,+New+York,+NY+10004">View Larger Map</a></small>
    </div>
  );
}

export default Contact;
