import Seo from "./Seo";
import { MailingAddress } from "./Contact";

function Privacy() {
    return (
        <div className="container">
            <Seo title="Privacy policy" />
            <div className="row">
                <h2>Privacy policy</h2>
                <p>
                    This Privacy Policy describes how AnyIntelli, Inc. ("we," "us," or "our") collects, uses, and discloses your information when you use our website (anyintelli.com) (the "Site").
                </p>

                <h3>Information We Collect</h3>
                <p>
                    We collect several different types of information for various purposes to improve our services to you.
                </p>

                <ul><ul><li> <b>Information You Provide Directly:</b> We may collect information you directly provide when you use the Site, such as:</li>
                    <ul>
                        <li> Contact information: Name, email address, phone number, city and state</li>
                        <li> Usage Data: When you visit the Site, we may collect information about your device and browsing activity, such as your IP address, browser type, operating system, referring URL, pages visited, and time spent on those pages. This information is collected through cookies and similar tracking technologies.</li>
                    </ul>
                    <li> <b>Google Analytics:</b> We use Google Analytics, a web analytics service offered by Google LLC ("Google"). Google Analytics uses cookies to collect information about your use of the Site and compile reports for us. The information collected by Google Analytics includes your IP address, browser type, referring/exit pages, and time spent on the Site. This information is used to improve the Site and to understand how visitors use it. Google's ability to use and share information collected by Google Analytics about your visits to the Site is restricted by the Google Analytics Terms of Service and the Google Privacy Policy. You can opt-out of Google Analytics tracking by disabling cookies in your browser settings.
                    </li>
                    <li><b>reCAPTCHA:</b> We use Google reCAPTCHA to protect our website from spam and abuse. reCAPTCHA may collect information about your hardware, software, and browsing activity to assess whether you are a human visitor.  reCAPTCHA's use of your information is governed by the Google Privacy Policy.
                    </li>
                </ul></ul>

                <h3>Use of Information</h3>

                <p>
                    We use the information we collect for various purposes, including:
                </p>
                <ul><ul>
                    <li> To operate and maintain the Site</li>
                    <li> To improve and personalize your experience on the Site</li>
                    <li> To analyze how you use the Site</li>
                    <li> To send you marketing and promotional communications (with your consent)</li>
                    <li> To respond to your inquiries and requests</li>
                    <li> To comply with legal and regulatory requirements</li>
                </ul></ul>
                <h3>Disclosure of Information</h3>

                <p>
                    We may disclose your information to third-party vendors and service providers who perform services for us, such as website hosting, data analysis, or marketing. These third parties are obligated to use your information only to provide the services we request.
                </p>
                <p>
                    We may also disclose your information if we are required to do so by law or if we believe it is necessary to protect the rights, property, or safety of ourselves or others.
                </p>
                <h3>Your Choices</h3>

                <p>
                    You have several choices regarding your information:
                </p>

                <ul><ul>
                    <li> <b>Cookies:</b> We use only strictly necessary cookies to operate the Site.</li>
                    <li> <b>Marketing Communications:</b> You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in those communications.</li>
                </ul>
                </ul>

                <h3>Data Retention</h3>

                <p>
                    We will retain your information for as long as necessary to fulfill the purposes for which it was collected, as described in this Privacy Policy. We will also retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                </p>

                <h3>Your information</h3>

                <p>
                    You have the right to:
                </p>
                <ul><ul>
                    <li> Know what categories of personal information we collect about you and how we use that information.</li>
                    <li> Request to delete your personal information.</li>
                    <li> Opt-out of the sale of your personal information (we do not sell your personal information).</li>
                </ul></ul>
                <p>
                    You can exercise your rights by contacting us using the information below.
                </p>
                <h3>Changes to this Privacy Policy</h3>

                <p>
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Site. You are advised to review this Privacy Policy periodically for any changes.
                </p>
                <h3>Contact Us</h3>

                <p>
                    If you have any questions about this Privacy Policy, please contact us by email at compliance@anyintelli.com or by mail at:
                </p>

                <MailingAddress />
                <h3>Compliance with US Law</h3>

                <p>
                    This Privacy Policy is intended to comply with applicable US privacy laws and regulations.
                </p>
            </div>
        </div>
    );
}

export default Privacy;
