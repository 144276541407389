import Seo from "./Seo";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

function Workshops() {
  return (
    <div className="container">
      <Seo title="Workshops" />
      <h2>Workshops <sup>beta</sup></h2>

      <div className="row featurette text-center mb-3">
        <div className="col-md-4 text-purple-900 mb-1">
          
          <div className="card h-100 bc-purple-premium1 shadow">
            <div className="card-body">
              <h4 className="card-title">Startup blueprint</h4>
              <p className="card-text">Workshops.</p>
              <p className="card-text">SDLC and operations templates, and service baselines.</p>
              <p className="card-text">This is designed to empower early startups allowing for setting well technology operations.</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
            <Link className="btn btn-sm btn-success" to="/intro/workshops-1">Request a demo</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#workshop1">Learn more</a>
            </div>
          </div>
        </div>

        <div className="col-md-4 text-dark mb-1">
          <div className="card h-100 bc-purple-premium2 shadow">
            <div className="card-body">
              <h4 className="card-title">Startup blueprint+</h4>
              <p className="card-text">Workshops+Team building.</p>
              <p className="card-text">Everything that Startup blueprint offers.</p>
              <p className="card-text">Plus interviewing and onboarding players which will deliver Minimal Viable Product (MVP).</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
            <Link className="btn btn-sm btn-success" to="/intro/workshops-2">Request a demo</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#workshop2">Learn more</a>
            </div>

          </div>
        </div>

        <div className="col-md-4 mb-1">
          <div className="card h-100 bc-purple-premium3 shadow">
            <div className="card-body">
              <h4 className="card-title">Startup blueprint premium+</h4>
              <p>Workshops+MVP+Team building.</p>
              <p>Everything that Startup blueprint+ offers.</p>
              <p>Plus Minimal Viable Product (MVP) design and delivery.</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
            <Link className="btn btn-sm btn-success" to="/intro/workshops-3">Request a demo</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#workshop3">Learn more</a>
            </div>

          </div>
        </div>
      </div>

      <div className="row featurette">

        <div className="col-md-7">
          <img className="featurette-image img-fluid" src="/img/main/workshops2.jpg" alt="Growth" width="800px" height="800px" />
        </div>

        <div className="col-md-5">
          <p>Technology leaders can build up their software development team(s) via a set of personalized growth and productivity operation-first workshops.</p>
          <p>
            This spreads from <b>technical areas</b> like database scaling, ETL, observability, authentication, SDLC, resiliency, risk management, as well as team development <b>leadership as a service</b> programs.
            Workshops designed around theme of building scalable and reliable software technology operations for a startup.</p>

          <h4>Startup blueprint series</h4>
          <p>
            As a founder you are racing against time. Software choices might take considerable amount of time
            which need to be focused on delivering business goals. Engineering quality is often sacrificed  at earlier
            stages just to find out it will be a drag and eat up considerable portion at the next rounds.
          </p>

          <p>
            With these series important choices are driven by technology practice. Allowing for <a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377752740704256" target="_blank" rel="noreferrer">more cycles</a> to improve, and thus - software that scales.
          </p>




        </div>

        <div className="row">

          <div className="col-md-4 mb-1">
            <div className="card h-100 bc-secondary shadow">
              <div className="card-header text-center">
                <p><i className="bi bi-info" /> Ideal for teams looking to establish modern resilient and reliable operations</p>
              </div>

              <div className="card-body">
                <h4 className="card-title text-center" id="workshop1">Startup blueprint</h4>
                <p><i className="bi bi-check" /> When team is in place and needs a modern technology operations template</p>
                <p><i className="bi bi-check" /> Tailored to your risk management domain</p>
                <p><i className="bi bi-check" /> Workshop onboarding session</p>
                <p><i className="bi bi-x" /> Interviewing</p>
                <p><i className="bi bi-x" /> Scouting not included</p>
                <p><i className="bi bi-x" /> MVP build-out is not included</p>
              </div>
              <div className="card-footer text-center bg-transparent border-0">
                  <Link className="btn btn-md btn-success" to="/intro/workshops-1">Request a demo</Link>
                </div>
            </div>
          </div>

          <div className="col-md-4 mb-1">
            <div className="card h-100 bc-secondary shadow">
              <div className="card-header text-center">
                <p><i className="bi bi-info" /> Ideal for technical co-founders looking for extra help during heavy lifting</p>
              </div>
              <div className="card-body">
                <h4 className="card-title text-center" id="workshop2">Startup blueprint+</h4>

                <p><i className="bi bi-arrow-left-short" /> Everything from Startup blueprint</p>
                <p><i className="bi bi-check" /> When you want an example, but also want a technology person to help you assembling the team</p>
                <p><i className="bi bi-check" /> Onboarding for duration of raising a team</p>
                <p><i className="bi bi-check" /> Interviewing</p>
                <p><i className="bi bi-x" /> Scouting not included</p>
                <p><i className="bi bi-x" /> MVP build-out is not included</p>
              </div>
              <div className="card-footer text-center bg-transparent border-0">
                  <Link className="btn btn-md btn-success" to="/intro/workshops-2">Request a demo</Link>
                </div>
            </div>
          </div>

          <div className="col-md-4 mb-1">
            <div className="card h-100 bc-secondary shadow">
              <div className="card-header text-center">
                <p><i className="bi bi-info" /> Ideal for founders looking for technical expertise for building reliable operated at scale technology{/*willing to take on team founding risks.*/}</p>
              </div>
              <div className="card-body">
                <h4 className="card-title text-center" id="workshop3">Startup blueprint premium+</h4>
                <p><i className="bi bi-arrow-left-short" /> Everything from Startup blueprint+</p>
                <p><i className="bi bi-check" /> MVP build-out</p>
                <p><i className="bi bi-check" /> Scouting for core team members</p>
                <p><i className="bi bi-check" /> Technical co-founding option can be discussed </p>
              </div>
              <div className="card-footer text-center bg-transparent border-0">
                  <Link className="btn btn-md btn-success" to="/intro/workshops-3">Request a demo</Link>
              </div>
            </div>
          </div>

        </div>


      </div>

    </div>
  );
}

export default Workshops;
