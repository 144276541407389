import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import useIsLoggedIn from "./useIsLoggedIn";

function Locked(props) {
    const isLoggedIn = useIsLoggedIn();

    return (!isLoggedIn()) && 
        <p className="text-center"><i className="bi bi-lock-fill fs-4" /> This section of the website is{props.isPartial ? " partially" : ""} protected and requires you to be <Link to="/signin">signed in</Link></p>;
}

export default Locked;
