import Seo from "./Seo";
import { useParams } from "react-router-dom";
import Locked from "./Locked";
import ApiClient from "./ApiClient";
import { useQuery } from "react-query";

function Playbook() {
  let { id = -1 } = useParams();

  const playbookQuery = useQuery(['playbook', id], () => { return ApiClient.getPlaybook(id) });

  return (
    <div className="container">
      <Seo title="Playbook details" />
      <h2 className="form-signin-heading">{playbookQuery.data && playbookQuery.data.name}</h2>
      <Locked isPartial="true" />
      <div className="row">
        <p>{playbookQuery.data && playbookQuery.data.description_1}</p>
        <p>{playbookQuery.data && playbookQuery.data.description_2}</p>
        <p>
          {playbookQuery.data && playbookQuery.data.description_3}
        </p>
      </div>
    </div>
  );
}

export default Playbook;
