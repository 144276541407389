import Seo from "./Seo";
import { Link } from "react-router-dom";

function Practice() {
  return (
    <div className="container">
      <Seo title="Technology practice" />
      <h2>Practice</h2>

      <div className="row featurette">

        <div className="col-md-4">
          <img className="featurette-image img-fluid" src="/img/main/practice-team.jpg" alt="Growth" width="800px" height="800px" />
        </div>

        <div className="col-md-4">
          <h5>Mission</h5>
          <p>Sustainable teams driven by resiliency and reliability of their systems.</p>

          <h5>Vision</h5>
          <ul>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113376351708958721" target="_blank" rel="noreferrer">Alpha is as strong as omega resilient</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113376629912952833" target="_blank" rel="noreferrer">Keep technology menu short</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113376695117570051" target="_blank" rel="noreferrer">Go places with each failure</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377662588342273" target="_blank" rel="noreferrer">Virtual facilities define uniqueness of a business</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377752740704256" target="_blank" rel="noreferrer">More cycles you continue, better process you get</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377827604819968" target="_blank" rel="noreferrer">Strategy is all about starting and stopping in time</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377887428169728" target="_blank" rel="noreferrer">“Non progredi est regredi”</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7119891682396909568" target="_blank" rel="noreferrer">Execution is the key</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7121266196234035200" target="_blank" rel="noreferrer">Technology and product balance</a></li>
            <li><a href="https://www.linkedin.com/posts/anyintelli_activity-7231774802544398336-Y-aW" target="_blank" rel="noreferrer">"Triangle defense": customer, team, and long value</a></li>
            <li><a href="https://www.linkedin.com/pulse/signature-artisan-anyintelli-t9ycf" target="_blank" rel="noreferrer">Signature of the artisan</a></li>
            <li>Decentralize</li>
            <li>Leadership as a service</li>
          </ul>

          <h5>Focus & scope</h5>
          <p>Main focus is growing software development technology and teams specialized on SaaS leveraging cloud.</p>

          <h5>Themes</h5>

          <h6>Meaningful and productive career</h6>
          <p><Link to="/elevate">Elevate</Link> is focused on setting good incentives and meaningful productive career for seasoned professionals through sustainable development.</p>

          <h6>Produce</h6>
          <p><Link to="/workshops">Workshops</Link> series are for founders, startups and companies interested in improving resiliency and reliability of their technologies.</p>
          <p><Link to="/ata">ATA</Link> is for established products and enterprise customers interested in growing startup for their needs.</p>

          <h6>Beyond hiring</h6>
          <p><Link to="/draft">AnyIntelli Draft</Link> section is focused on forming the teams. <b>Teams where ideas grow</b>.</p>
        </div>

        <div className="col-md-4">
        <img className="featurette-image img-fluid" src="/img/main/tttr.png" alt="Team, Technology, Resilience, Transactions." width="600px" height="600px" />
        </div>



      </div>
    </div>
  );
}

export default Practice;
